import {A, B, E1, E2, N, O, P, R, T} from "../../../../imgs";
import Liquid from "../../liquid/Liquid";
import BeerLogoTile from "../../beerLogoTile/BeerLogoTile";
import Tap from "../../tap/Tap";
import NameTile from "../../nameTile/NameTile";
import {useEffect, useRef, useState} from "react";
import pourRandomBeer from "../funcs/pourRandomBeer";

const MobileWall = (props) => {
  let {beers, setBeerCard, width} = props;
  let [tapsOn, setTapsOn] = useState(Array(10).fill(false));

  useEffect(() => {
    return pourRandomBeer(tapsOn, setTapsOn);
  }, [tapsOn]);

  return (
    <div className="grid-container">
      {/* Row 1 */}
      <div className={'grid-item'}>
        <img className={'beerOnTapLetter'} src={B} alt={'Handwritten letter B'}/>
      </div>
      <div className={'grid-item'}>
        <img className={'beerOnTapLetter'} src={E1} alt={'Handwritten letter E'}/>
      </div>
      <div className={'grid-item'}>
        <img className={'beerOnTapLetter'} src={E2} alt={'Handwritten letter E'}/>
      </div>
      <div className={'grid-item'}>
        <img className={'beerOnTapLetter'} src={R} alt={'Handwritten letter R'}/>
      </div>
      {/* Row 2 */}
      <div className={'grid-item'}>
        <img className={'beerOnTapLetter'} src={O} alt={'Handwritten letter O'}/>
      </div>
      <div className={'grid-item'}>
        <img className={'beerOnTapLetter'} src={N} alt={'Handwritten letter O'}/>
      </div>
      <div className={'grid-item'}></div>
      <div className={'grid-item'}></div>
      {/* Row 3 */}
      <div className={'grid-item'}>
        <img className={'beerOnTapLetter'} src={T} alt={'Handwritten letter T'}/>
      </div>
      <div className={'grid-item'}>
        <img className={'beerOnTapLetter'} src={A} alt={'Handwritten letter A'}/>
      </div>
      <div className={'grid-item'}>
        <img className={'beerOnTapLetter'} src={P} alt={'Handwritten letter P'}/>
      </div>
      <div className={'grid-item'}></div>
      {/* Row 4 */}
      <Liquid tapOn={tapsOn[0]} beer={beers[0]} row={1} mobile={true}/>
      <div className={'grid-item'}></div>
      <Liquid tapOn={tapsOn[1]} beer={beers[1]} row={1} mobile={true}/>
      <div className={'grid-item'}></div>
      {/* Row 5 */}
      <BeerLogoTile beer={beers[0]} setBeerCard={setBeerCard}/>
      <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={0}/>
      <BeerLogoTile beer={beers[1]} setBeerCard={setBeerCard}/>
      <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={1}/>
      {/* Row 6 */}
      <NameTile beer={beers[0]} width={width}/>
      <Liquid tapOn={tapsOn[2]} beer={beers[2]} row={2} mobile={true}/>
      <NameTile beer={beers[1]} width={width}/>
      <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={3} edge={true}/>
      {/* Row 7 */}
      <Liquid tapOn={tapsOn[3]} beer={beers[3]} row={2} edge={true} mobile={true}/>
      <BeerLogoTile beer={beers[2]} setBeerCard={setBeerCard}/>
      <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={2}/>
      <BeerLogoTile beer={beers[3]} setBeerCard={setBeerCard}/>
      {/* Row 8 */}
      <Liquid tapOn={tapsOn[4]} beer={beers[4]} row={3} mobile={true}/>
      <NameTile beer={beers[2]} width={width}/>
      <Liquid tapOn={tapsOn[5]} beer={beers[5]} row={3} mobile={true}/>
      <NameTile beer={beers[3]} width={width}/>
      {/* Row 9 */}
      <BeerLogoTile beer={beers[4]} setBeerCard={setBeerCard}/>
      <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={4}/>
      <BeerLogoTile beer={beers[5]} setBeerCard={setBeerCard}/>
      <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={5}/>
      {/* Row 10 */}
      <NameTile beer={beers[4]} width={width}/>
      <Liquid tapOn={tapsOn[6]} beer={beers[6]} row={4} mobile={true}/>
      <NameTile beer={beers[5]} width={width}/>
      <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={7} edge={true}/>
      {/* Row 11 */}
      <Liquid tapOn={tapsOn[7]} beer={beers[7]} row={4} edge={true} mobile={true}/>
      <BeerLogoTile beer={beers[6]} setBeerCard={setBeerCard}/>
      <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={6}/>
      <BeerLogoTile beer={beers[7]} setBeerCard={setBeerCard}/>
      {/* Row 12 */}
      <Liquid tapOn={tapsOn[8]} beer={beers[8]} row={5} mobile={true}/>
      <NameTile beer={beers[6]} width={width}/>
      <Liquid tapOn={tapsOn[9]} beer={beers[9]} row={5} mobile={true}/>
      <NameTile beer={beers[7]} width={width}/>
      {/* Row 13 */}
      <BeerLogoTile beer={beers[8]} setBeerCard={setBeerCard}/>
      <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={8}/>
      <BeerLogoTile beer={beers[9]} setBeerCard={setBeerCard}/>
      <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={9}/>
      {/* Row 14 */}
      <NameTile beer={beers[8]} width={width}/>
      <div className={'grid-item'}></div>
      <NameTile beer={beers[9]} width={width}/>
      <div className={'grid-item'}></div>
      {/* Row 15 */}
      <div className={'grid-item'}></div>
      <div className={'grid-item'}></div>
      <div className={'grid-item'}></div>
      <div className={'grid-item'}></div>
    </div>
  )
}

export default MobileWall;