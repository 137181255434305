import './beerLogoTile.css';
import './beerLogoTileMobile.css';
import './beerLogoTileTablet.css';

import { useRef, useState } from "react";
import NewStar from "../newStar/NewStar";

const BeerLogoTile = (props) => {
  let { beer, setBeerCard } = props;
  const imgRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className={'grid-item'}>
      <NewStar beer={beer} />
      {isLoading && (
        <div className="loadingPlaceholder"></div>
      )}
      <img
        ref={imgRef}
        className={`beerLogoImg ${isLoading ? 'hidden' : ''}`}
        onClick={() => {
          setBeerCard(beer);
        }}
        src={beer.imageUrl}
        alt={beer.name !== '' ? beer.name + " logo" : ''}
        onLoad={handleImageLoad}
      />
    </div>
  );
}

export default BeerLogoTile;