import './newStar.css';
import './newStarMobile.css';
import './newStarTablet.css';

import {Timestamp} from "firebase/firestore";

const NewStar = (props) => {
  const { beer, style } = props;

  function isTimestampGreaterThanWeekFromToday(timestamp) {
    const firebaseTimestamp = Timestamp.fromMillis(timestamp.seconds * 1000);

    const aWeekAgo = new Date();
    aWeekAgo.setDate(aWeekAgo.getDate() - 7);

    const timestampDate = firebaseTimestamp.toDate();
    return timestampDate > aWeekAgo;
  }

  return (
    <>
      {beer.lastUpdated !== undefined && isTimestampGreaterThanWeekFromToday(beer.lastUpdated) ?
        <div className={`star ${style}`}>
          <p>NEW!</p>
        </div> : <></>}
    </>
  )
}

export default NewStar;