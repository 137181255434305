// Stylesheets
import './quizBookingInfo.css';
import './quizBookingInfoMobile.css';
import './quizBookingInfoTablet.css';

import {useEffect, useState} from "react";
import {getNext10WednesdaysWithSoldOut, updateArrayAtIndex} from "../../../func";
import {Booking, TableLayout} from "../../../objects";
import {Timestamp} from "firebase/firestore"
import {findXTablesOfX} from "../../../pages/updateBooking/betterTableFuncs";
import {getLayout} from "../../../pages/updateBooking/firebaseFuncs";
import {tables} from "../../../pages/updateBooking/initTables";
import {testTeams5} from "../../../pages/admin/quizTables/testData";

const QuizBookingInfo = (props) => {
  let {translate, movePage, bookingInfo, setBookingInfo, width, update, bookingId} = props;

  const [wednesdays, setWednesdays] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [people, setPeople] = useState([0]);
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  const [soldOut, setSoldOut] = useState(false);
  const [layout, setLayout] = useState(null);
  const [tablesRemaining, setTablesRemaining] = useState([])
  const [largestEmptyCapacity, setLargestEmptyCapacity] = useState(null);

  // Current Booking (Update)
  const [currentTeamSize, setCurrentTeamSize] = useState(null);
  const [currentNumberOfTeams, setCurrentNumberOfTeams] = useState(null);
  const [currentEmptySpaces, setCurrentEmptySpaces] = useState(null);

  function getMaxTeamSizes() {
    if (layout !== null) {
      setTablesRemaining(layout.emptyTables);

      if (update && bookingInfo && soldOut) { // If user is trying to update a booking while it is sold out
        setPeople(bookingInfo.size)
        let size = bookingInfo.size.reduce((a, b) => a + b, 0);
        setCurrentTeamSize(size);
        setCurrentNumberOfTeams(bookingInfo.size.length);

        let currentTables = layout.assignedTables.filter(table => table.teams.find(team => team.bookingId === bookingId));
        let emptySpaces = currentTables.reduce((sum, table) => {
          return sum + (table.capacity - table.currentSize);
        }, 0)
        setCurrentEmptySpaces(emptySpaces);
        setLargestEmptyCapacity(emptySpaces + size);

        if ((emptySpaces + size) > 6) {
          let maxNumberOfTeams = Math.ceil(((emptySpaces + size) / 6));
          setCurrentNumberOfTeams(maxNumberOfTeams);
        }
      } else if (update && bookingInfo && !soldOut) { // If a user is trying to update why the quiz is NOT sold out
        setPeople(bookingInfo.size)
        let size = bookingInfo.size.reduce((a, b) => a + b, 0);
        let assignedTables = layout.assignedTables.filter(table => table.teams.find(team => team.bookingId === bookingId));
        let assignedTableSize = assignedTables.map((table) => (table.capacity)).reduce((a, b) => a + b, 0)
        if (size > assignedTableSize) {
          size = assignedTableSize
        }
        setCurrentTeamSize(size);
        setCurrentNumberOfTeams(bookingInfo.size.length);

        let currentTables = layout.assignedTables.filter(table => table.teams.find(team => team.bookingId === bookingId));
        let emptySpaces =
          layout.emptyTables.reduce((sum, table) => {
            if (table.id !== 8) {
              return sum + (table.capacity - table.currentSize);
            } else {
              return sum;
            }
          }, 0)
        if (assignedTables.length === 1 && layout.emptyTables.length <= 2) {
          emptySpaces = currentTables.reduce((sum, table) => {
            return sum + (table.capacity - table.currentSize);
          }, 0);
        }

        // If empty spaces is somehow negative, or team is assigned to 3 tables or more. Restrict them to their table size
        if (assignedTables.length > 2 || emptySpaces < 0) {
          emptySpaces = 0;
        }

        setCurrentEmptySpaces(emptySpaces);
        setLargestEmptyCapacity(emptySpaces + size);

        if ((emptySpaces + size) > 6) {
          let maxNumberOfTeams = Math.ceil(((emptySpaces + size) / 6));
          setCurrentNumberOfTeams(maxNumberOfTeams);
        }
      } else { // If a user is trying to book fresh
        // Number of empty spaces across all tables without a team currently seated.
        let emptySpaces = 0;

        if (layout.emptyTables.length === 1 && layout.emptyTables[0].id === 8) {
          emptySpaces = 3
        } else {
          emptySpaces = layout.emptyTables.reduce((sum, table) => {
            if (table.id !== 8) {
              return sum + (table.capacity - table.currentSize);
            } else {
              return sum;
            }
          }, 0)
        }

        if (emptySpaces > 6) {
          let maxNumberOfTeams = Math.ceil(emptySpaces / 6)
          setCurrentNumberOfTeams(maxNumberOfTeams)
        }
        setCurrentEmptySpaces(emptySpaces);
        // console.log(emptySpaces)
        // setLargestEmptyCapacity(emptySpaces)

        setLargestEmptyCapacity(layout.emptyTables.reduce((maxCapacity, tableObj) => {
          return (tableObj.currentSize === 0 && tableObj.capacity > maxCapacity)
            ? tableObj.capacity > 6
              ? 6 :
              tableObj.capacity < 4
                ? findXTablesOfX(layout.emptyTables, 2, 3).length >= 3
                  ? 6 : findXTablesOfX(layout.emptyTables, 2, 2).length >= 2
                    ? 4 : tableObj.capacity
                : tableObj.capacity
            : maxCapacity;
        }, 0));
      }
    } else if (largestEmptyCapacity === null) {
      setLargestEmptyCapacity(6)
    }
  }

  useEffect(() => {
    getMaxTeamSizes(currentTeamSize, currentEmptySpaces);
  }, [bookingInfo, update, layout, soldOut]);

  useEffect(() => {
    if (date !== "" && wednesdays.length > 0) {
      if (wednesdays.find(element => element.includes(date)).includes(' [SOLD OUT]')) {
        setSoldOut(true)
      } else {
        setSoldOut(false)
      }

      let getDate
      if (wednesdays.length > 0) {
        if (!update) {
          getDate = date;
        } else {
          getDate = wednesdays[wednesdays.findIndex(element => element.includes(date))].split(' [SOLD OUT]')[0]
        }
      }

      // const {
      //   assignedTables,
      //   printBookings,
      //   noSeats,
      //   emptySpaces,
      //   noTables,
      //   total,
      //   foldingChairs
      // } = assignTables(tables, tables, testTeams5);
      // console.log(assignedTables,
      //   printBookings,
      //   noSeats,
      //   emptySpaces,
      //   noTables,
      //   total)
      // setLayout(new TableLayout(date, assignedTables, noTables, emptySpaces, total))
      getLayout(getDate).then((doc) => {
        if (doc !== null) {
          setLayout(doc);
        } else {
          setLayout(new TableLayout(
            getDate,
            [],
            tables,
            63,
            5,
            0
          ))
        }
      })
    }
  }, [date, wednesdays]);

  useEffect(() => {
    if (wednesdays.length === 0) {
      getNext10WednesdaysWithSoldOut().then(newWeds => {
        setWednesdays(newWeds);
      })
    }

    if (wednesdays.length > 0 && !update) {
      setDate(wednesdays[0]);
    }
  }, [wednesdays]);

  useEffect(() => {
    if (bookingInfo) {
      setName(bookingInfo.name);
      setEmail(bookingInfo.email);
      setTime(bookingInfo.time);
      setPeople(bookingInfo.size)
      setDate(bookingInfo.date)
    }
  }, [bookingInfo, update]);

  const teamSizeSelect = (id) => {
    return (
      <>
        {people.length > 1 ? <div className={'teamTitle'}>Team {id + 1}</div> : <></>}
        <select key={`select-${id}`} className={'quizSelect'} id={'time'} onChange={(event) => {
          updateArrayAtIndex(people, setPeople, id, parseInt(event.target.value));
        }} value={people[id]}>
          <option value={0}>Team size
            ({(currentEmptySpaces >= 6 || (currentEmptySpaces <= 0 && tablesRemaining.length <= 0)) && largestEmptyCapacity >= 6
              ?
              `Max 6 people per team`
              :
              !update
                ?
                `Only ${tablesRemaining.length > 1
                  ?
                  `${largestEmptyCapacity === 3 ? `1 table` : `${tablesRemaining.length} tables`}`
                  :
                  `1 table`} ${currentEmptySpaces === largestEmptyCapacity ? `of ${currentEmptySpaces}` : ``} left${tablesRemaining.length < 2
                  ?
                  `!`
                  :
                  ``}`
                :
                (currentEmptySpaces + currentTeamSize) < currentTeamSize
                  ?
                  `Cannot exceed original booking`
                  :
                  soldOut
                    ?
                    `Cannot exceed current table size`
                    :
                    `Cannot exceed pub capacity`
            })
          </option>
          {Array.from({
              length: Math.min(
                id + 1 === currentNumberOfTeams && id !== 0
                  ?
                  (!update
                    ?
                    currentEmptySpaces - (people.length > 2 ? people.slice(0, -1).reduce((a, b) => a + b, 0) : people[0])
                    :
                    largestEmptyCapacity - (people.length > 2 ? people.slice(0, -1).reduce((a, b) => a + b, 0) : people[0]))
                  : largestEmptyCapacity,
                6 // Ensure the length never exceeds 6
              )
            }, (v, k) => k + 1
          ).map((index) => {
            return (
              <option key={`option-${index}`} value={index}>{index}</option>
            )
          })}
        </select>
      </>
    )
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div style={{translate: translate}} className={'quizBookingSection quizBookingInfoContainer'}>
      <div className={'quizFieldsContainer'}>
        {!update ? <h1>BOOK A QUIZ TABLE</h1> : <h1>UPDATE BOOKING</h1>}
        {!soldOut || update ? <>
          <input id={'fname'} onChange={(event) => {
            setName(event.target.value);
          }} placeholder={"Name for booking"} value={name} type={'fname'} autoComplete={'given-name'}></input>
          <input id={'email'} onChange={(event) => {
            setEmail(event.target.value);
          }} placeholder={"Email (For booking confirmation)"} value={email} type={'email'}
                 autoComplete={'email'}></input>
          {teamSizeSelect(0)}
          {people.length > 1 ? people.map((item, index) => {
            return (
              index > 0 ? teamSizeSelect(index) : <></>
            )
          }) : <></>}
          {
            (currentEmptySpaces >= 6 && !soldOut) || (update && currentNumberOfTeams > 1)
              ?
              <div className={'bookAnotherContainer'}>
                <span>Need to book another team?</span>
                <button
                  disabled={
                    people.length > 2 || (currentNumberOfTeams === people.length)
                  }
                  className={'adminBtn bookAnotherBtn bookAnotherPlus'} onClick={() => {
                  if (people.length < 3) {
                    setPeople(people => ([...people, 0]))
                  }
                }}>+
                </button>
                <button disabled={people.length < 2} className={'adminBtn bookAnotherBtn bookAnotherMinus'}
                        onClick={() => {
                          if (people.length > 1) {
                            setPeople(people => people.slice(0, people.length - 1));
                          }
                        }}>-
                </button>
              </div> : <></>}
          <select id={'time'} onChange={(event) => {
            setTime(event.target.value);
          }} value={time}>
            <option value={""}>Time for table (6:45pm - 7:45pm)</option>
            <option value={"6:45pm"}>6:45pm</option>
            <option value={"7:00pm"}>7:00pm</option>
            <option value={"7:15pm"}>7:15pm</option>
            <option value={"7:30pm"}>7:30pm</option>
            <option value={"7:45pm"}>7:45pm</option>
          </select>
        </> : <>
          <div className={'soldOutMessage'}>
            The quiz this week is <b>sold out</b>! However, feel free to <b>choose another week</b> to get your table
            booking in early.
          </div>
          <br/>
        </>}

        <select id={'wednesdays'} onChange={(event) => {
          setDate(event.target.value);
        }} value={date}>
          {wednesdays.map((date, index) => (
            <option key={index} value={date}>
              {date}
            </option>
          ))}
        </select>
      </div>

      <div className={'quizBtnContainer'}>
        <button onClick={() => {
          let valid = false;

          while (!valid) {
            if (name === "") {
              alert("Please enter your name")
              break;
            }
            if (time === "") {
              alert("Please select a time for your table")
              break;
            }
            if (!validateEmail(email)) {
              alert("Please enter a valid email address")
              break;
            }
            if (people.filter(team => team === 0).length > 0) {
              alert("Make sure you have selected a size for your team(s)")
              break;
            }
            if ((people.reduce((a, b) => a + b, 0) > currentTeamSize && soldOut) && ((people.reduce((a, b) => a + b, 0) > (currentTeamSize + currentEmptySpaces)) && soldOut)) {
              alert("The quiz has sold out! You can only change your team size to less than you originally booked for.")
              break;
            }
            valid = true;
          }

          if (valid) {
            const expireDate = new Date(date);
            expireDate.setDate(expireDate.getDate() + 14);
            setBookingInfo(
              new Booking(
                name,
                email,
                people,
                date,
                time,
                new Date(),
                Timestamp.fromDate(expireDate))
            );
            movePage(2)
          } // TODO: If authenticated then just update without email...

        }} className={'adminBtn quizBtn'} disabled={soldOut && !update}>
          {soldOut && !update ? "SOLD OUT" : `${!update ? `SEND` : `UPDATE`} BOOKING`}
        </button>
        {!update ?
          <div className={'contactQuiz'}>Contact <a href={"mailto:info@mosaictap.com"}>info@mosaictap.com</a> for
            special
            requests
          </div> : <></>}
      </div>
    </div>
  )
}

export default QuizBookingInfo;