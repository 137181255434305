export class Beer {
  constructor (name, description, brewery, breweryLocation, breweryCountry, imageUrl, abv, style, pageUrl, tapColour, position, lastUpdated) {
    this.name = name;
    this.description = description;
    this.brewery = brewery;
    this.breweryLocation = breweryLocation;
    this.breweryCountry = breweryCountry;
    this.imageUrl = imageUrl;
    this.abv = abv;
    this.style = style;
    this.pageUrl = pageUrl;
    this.tapColour = tapColour;
    this.position = position;
    this.lastUpdated = lastUpdated;
  }
}

export const beerConverter = {
  toFirestore: (beer) => {
    return {
      name: beer.name,
      description: beer.description,
      brewery: beer.brewery,
      breweryLocation: beer.breweryLocation,
      breweryCountry: beer.breweryCountry,
      imageUrl: beer.imageUrl,
      abv: beer.abv,
      style: beer.style,
      pageUrl: beer.pageUrl,
      tapColour: beer.tapColour,
      position: beer.position,
      lastUpdated: beer.lastUpdated
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Beer(data.name, data.description, data.brewery, data.breweryLocation, data.breweryCountry,
        data.imageUrl, data.abv, data.style, data.pageUrl, data.tapColour, data.position, data.lastUpdated);
  }
}