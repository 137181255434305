import './visitUs.css';
import './visitUsMobile.css';
import './visitUsTablet.css';
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {hourConverter as hoursConverter, Hours} from "../../objects/Hours";
import {useEffect, useState} from "react";

const VisitUs = (props) => {
    let width = props.width;

    const [hours, setHours] = useState(new Hours('Closed', 'Closed', '3pm-11pm',
        '3pm-11pm', '3pm-11pm', '1pm-11pm', '3pm-10pm', '30 mins before close'))
    const [christmasHours, setChristmasHours] = useState({
        "2212": "1pm-9pm",
        "2312": "2pm-10pm",
        "2412": "Closed",
        "2512": "Closed",
        "2612": "1pm-9pm",
        "2712": "3pm-11pm",
        "2812": "1pm-11pm",
        "2912": "1pm-9pm",
        "3012": "Closed",
        "3112": "7pm-2am",
        "0101": "Closed",
    })
    const [groupedHours, setGroupedHours] = useState([])
    const [showChristmasHours, setShowChristmasHours] = useState(null)
    const [isItChristmas, setIsItChristmas] = useState(false);

    const getCurrentHours = async () => {
        if (showChristmasHours !== null) {
            if (showChristmasHours) {
                setHours(christmasHours);
            } else {
                const docHours = await getDoc(doc(db, "openingHours", "hours"));
                setHours(hoursConverter.fromFirestore(docHours))
            }
        } else {
            const docHours = await getDoc(doc(db, "openingHours", "hours"));
            setHours(hoursConverter.fromFirestore(docHours))
        }
    }

    const formatHours = (rewriteFunction) => {
        let groups = findIdenticalValues(hours)
        let newGroups = []

        for (let i = 0; i < groups.length; i++) {
            let days = groups[i][0]
            let value = groups[i][1]

            if (days.length === 1) {
                newGroups.push([rewriteFunction(days[0]), value])
            } else if (days.length === 2) {
                newGroups.push([`${rewriteFunction(days[0])} & ${rewriteFunction(days[1])}`, value])
            } else {
                newGroups.push([`${rewriteFunction(days[0])} - ${rewriteFunction(days[days.length - 1])}`, value])
            }
        }

        setGroupedHours(newGroups)
    }

    function findIdenticalValues(obj) {
        const entries = Object.entries(obj);
        const result = [];
        let currentGroup = [];
        let currentValue = null;

        for (const [key, value] of entries) {
            if (value === currentValue) {
                currentGroup.push(key);
            } else {
                if (currentGroup.length > 0) {
                    result.push([currentGroup, currentValue]);
                }
                currentGroup = [key];
                currentValue = value;
            }
        }

        // Add the last group if it exists
        if (currentGroup.length > 0) {
            result.push([currentGroup, currentValue]);
        }

        return result;
    }

    useEffect(() => {
        const today = new Date();
        // 17th - 21st
        const startChristmasHours = new Date(today.getFullYear(), 11, 17);
        const endChristmasHours = new Date(today.getFullYear(), 11, 21);

        // 22nd - 1st
        const showChristmasHours = new Date(today.getFullYear(), 11, 22);
        const endShowChristmasHoursNextYear = new Date(today.getFullYear() + 1, 0, 1);
        const endShowChristmasHoursThisYear = new Date(today.getFullYear(), 0, 1);

        // 2nd Jan - 16th Dec
        const showChristmasButton = new Date(today.getFullYear(), 0, 2);
        const endShowChristmasButton = new Date(today.getFullYear(), 11, 16);

        if (today >= startChristmasHours && today <= endChristmasHours) {
            setShowChristmasHours(false);
        } else if ((today >= showChristmasHours && today <= endShowChristmasHoursNextYear) || (today < showChristmasButton)) {
            setShowChristmasHours(true)
        }

        setIsItChristmas(!(today >= showChristmasButton && today <= endShowChristmasButton))
    }, []);

    const getAbbreviation = (value) => {
        if (value === "monday") {
            return "Mon"
        } else if (value === "tuesday") {
            return "Tues"
        } else if (value === "wednesday") {
            return "Wed"
        } else if (value === "thursday") {
            return "Thurs"
        } else if (value === "friday") {
            return "Fri"
        } else if (value === "saturday") {
            return "Sat"
        } else if (value === "sunday") {
            return "Sun"
        } else if (value === "lastOrders") {
            return "Last Orders"
        } else {
            return value;
        }
    }

    function formatDateString(dateString) {
        // Extract day and month from the input string
        const day = parseInt(dateString.substring(0, 2));
        const month = parseInt(dateString.substring(2, 4)) - 1; // 0-indexed

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const date = new Date(year, month, day);

        // Get the day of the week abbreviation
        const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });

        let specialDay;
        if (month === 11) { // December
            switch (day) {
                case 24: specialDay = 'Christmas Eve'; break;
                case 25: specialDay = 'Christmas Day'; break;
                case 26: specialDay = 'Boxing Day'; break;
                case 31: specialDay = 'New Year\'s Eve'; break;
            }
        } else if (month === 0 && day === 1) { // January 1st
            specialDay = 'New Year\'s Day';
        }

        if (specialDay) {
            return `${specialDay}`;
        }

        let ordinalSuffix;
        if (day >= 11 && day <= 13) {
            ordinalSuffix = 'th';
        } else {
            switch (day % 10) {
                case 1: ordinalSuffix = 'st'; break;
                case 2: ordinalSuffix = 'nd'; break;
                case 3: ordinalSuffix = 'rd'; break;
                default: ordinalSuffix = 'th'; break;
            }
        }

        return `${dayOfWeek} ${day}${ordinalSuffix}`;
    }

    useEffect(() => {
        if (showChristmasHours !== null && showChristmasHours) {
            formatHours(formatDateString)
        } else {
            formatHours(getAbbreviation)
        }
    }, [hours]);

    useEffect(() => {
        getCurrentHours()
    }, [showChristmasHours])

    useEffect(() => {
        getCurrentHours()
    }, [])

    return (
        <div className={'visitUsHeader'}>
            <div className={'visitUsInnerHeaderContainer'}>
                <h1>VISIT US</h1>
                {isItChristmas ? <button
                    onClick={() => {
                        setShowChristmasHours(!showChristmasHours)
                    }}
                    className={'adminBtn showChristmasBtn'}
                    style={{
                        backgroundColor: !showChristmasHours ? "#c20000" : "#084525",
                        '--show': !showChristmasHours ? "radial-gradient(white 2px, transparent 2px)" : "none"
                    }}
                >
                    {showChristmasHours ? "SHOW NORMAL HOURS" : "SHOW CHRISTMAS HOURS"}
                </button> : <></>}
            </div>
            <div>
                { width > 640 ?
                    <div className={'openingHoursContainer'}>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index < 3) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div style={{marginBottom: showChristmasHours ? '5px' : "0px"}}>
                            {groupedHours.map((value, index) => {
                                if (index >= 3 && index < 7) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index >= 7) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    :
                    <div className={'openingHoursContainer'}>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index < 2) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (showChristmasHours ? index === 2 : index >= 2 && index < 4) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (showChristmasHours ? index >= 3 && index < 5 : index >= 4 && index < 6) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index >= 5 && index < 7) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index >= 7 && index < 9) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index >= 9) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default VisitUs;