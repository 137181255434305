// Stylesheets
import './updateBeers.css';
import './updateBeersMobile.css';

// Components
import ColourPicker from "../../../components/colourPicker/ColourPicker";
import {Beer, beerConverter} from "../../../objects";

// React
import {useEffect, useState} from "react";

// Firebase
import {collection, deleteDoc, doc, getDocs, orderBy, query, setDoc, where, Timestamp} from "firebase/firestore";
import {db} from "../../../firebase";
import {useNavigate} from "react-router-dom";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {Eyedropper, Loading, Reset, Upload} from "../../../imgs";
import Compressor from 'compressorjs'

const UpdateBeers = (props) => {
  const navigate = useNavigate();
  let {user, setUser} = props;

  // Auth
  let auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      navigate('/admin')
    }
  });

  // Firebase
  const storage = getStorage();
  let [startUpload, setStartUpload] = useState(false);
  let [progressString, setProgressString] = useState('');
  let [progressCounter, setProgressCounter] = useState(0)
  let [progressTotal, setProgressTotal] = useState(0)

  // Beer info
  let [beerName, setBeerName] = useState('');
  let [foundBeers, setFoundBeers] = useState([]); // All beers related to search
  let [currentBeers, setCurrentBeers] = useState([]); // Current beers in database
  let [selectedBeers, setSelectedBeers] = useState([]); // New beers to add
  let [removedBeers, setRemovedBeers] = useState([])
  let [addedBeers, setAddedBeers] = useState([])
  let [loadingBeers, setLoadingBeers] = useState(false)

  // Colour Picker
  let [pickerOpen, setPickerOpen] = useState(false);
  let [colourIndex, setColourIndex] = useState(0);
  let [currentItemColour, setCurrentItemColour] = useState('')

  const getCurrentBeers = async () => {
    let currentBeers = []
    const q = await query(collection(db, "beersOnTap"), orderBy("position"));
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      let beer = beerConverter.fromFirestore(doc);
      currentBeers.push(beer)
    });
    return currentBeers;
  }

  function getBeers() {
    setLoadingBeers(true);
    setFoundBeers([])
    fetch(`https://business.untappd.com//api/v1/items/search?q=${beerName.replace(' ', '-')}`, {
      headers: {
        Authorization: "Basic aXNhYWNAbW9zYWljdGFwLmNvbTpVTi1DRUMyTG9vVFBHdHF6NjFaVw==",
        "Content-Type": "application/json"
      },
      method: "GET"
    }).then(r => {
      return r.json()
    }).then(json => {
      if (json.items.length > 0) {
        setFoundBeers(json.items)
        setLoadingBeers(false);
      }
    })
  }

  // For removing from beer selection array
  const handleDelete = (arr, setArr, index) => {
    const newArray = arr.filter((item, i) => i !== index);
    setArr(newArray);
  };

  const updatePosition = (index, position) => {
    let newBeerArr = []
    for (let i = 0; i < selectedBeers.length; i++) {
      let newBeer = selectedBeers[i]
      if (i === index) {
        newBeer.position = position === '' ? '' : parseInt(position)
        newBeerArr.push(newBeer)
      } else {
        newBeerArr.push(selectedBeers[i])
      }
    }
    setSelectedBeers(newBeerArr)
  }

  const updateUrl = (index) => {
    let newBeerArr = []
    for (let i = 0; i < selectedBeers.length; i++) {
      let newBeer = selectedBeers[i]
      if (i === index) {
        newBeer.imageUrl = `https://labels.untappd.com/${newBeer.pageUrl.split("/").pop()}?size=hd`
        newBeerArr.push(newBeer)
      } else {
        newBeerArr.push(selectedBeers[i])
      }
    }
    setSelectedBeers(newBeerArr)
  }

  const uploadToStorage = async (beerObj) => {
    if (beerObj.imageUrl instanceof File || beerObj.imageUrl === '') {
      getDownloadURL(ref(storage, `Beer Images/${beerObj.name.replaceAll("/", "-")}`)).then(onResolve, onReject);

      function onResolve(foundURL) {
        //stuff
        console.log("found")
      }

      function onReject(error) {
        console.log("not found")
        console.log(error.code);
      }

      let storageRef = ref(storage, `Beer Images/${beerObj.name.replaceAll("/", "-")}`);
      const uploadTask = uploadBytesResumable(storageRef, beerObj.imageUrl);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          setProgressString(progress)
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          console.log("Uploaded custom image for", beerObj.name)
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            console.log('File available at', downloadURL);
            beerObj.imageUrl = downloadURL
            await uploadToFirestore(beerObj)
          });
        });
    } else {
      await uploadToFirestore(beerObj)
    }
  }

  const successfulUpload = () => {
    setTimeout(() => {
      alert('All beers saved successfully')
      setStartUpload(false)
      window.location.reload()
    }, 1000)
  }

  const uploadToFirestore = async (beer) => {
    const ref = doc(db, "beersOnTap", beer.name.replaceAll("/", "-"))
      .withConverter(beerConverter)
    await setDoc(ref, beer).then(() => {
      handleDelete(addedBeers, setAddedBeers, 0)
    })
  }

  const deleteRemovedBeers = async (position) => {
    const q = await query(
      collection(db, "beersOnTap"),
      where("position", "==", position)
    );
    const querySnapshot = await getDocs(q)
    if (querySnapshot.docs.length === 1) {
      await deleteDoc(doc(db, "beersOnTap", querySnapshot.docs[0].id));
    } else {
      console.log("No such beer");
    }
  }

  // Start uploading if SAVE is clicked
  const saveBeers = () => {
    if (selectedBeers.length !== 10) {
      alert("You must have exactly 10 beers selected")
    } else {
      let {removedBeers, addedBeers} = getBeerDifferences(currentBeers, selectedBeers)
      console.log(removedBeers, addedBeers)
      if (addedBeers.length === 0 && removedBeers.length === 0) {
        alert("No new beers found");
      } else {
        setRemovedBeers(removedBeers);
        setAddedBeers(addedBeers);
        setStartUpload(true);
        setProgressCounter(progressTotal - (addedBeers.length + removedBeers.length))
        setProgressTotal(removedBeers.length + addedBeers.length)
      }
    }
  }

  useEffect(() => {
    if (startUpload && removedBeers.length > 0) {
      deleteRemovedBeers(removedBeers[0].position).then(r => {
        handleDelete(removedBeers, setRemovedBeers, 0);
      })
    }
  }, [startUpload, removedBeers]);

  useEffect(() => {
    if (startUpload && addedBeers.length > 0 && removedBeers.length === 0) {
      uploadToStorage(addedBeers[0]);
    }
    if (startUpload && addedBeers.length === 0 && removedBeers.length === 0) {
      successfulUpload();
    }
    if (startUpload) {
      setProgressCounter(progressTotal - (addedBeers.length + removedBeers.length))
    }
  }, [startUpload, addedBeers, removedBeers]);

  useEffect(() => {
    setProgressString(`${progressCounter / progressTotal * 100}%`)
  }, [progressCounter]);

  // Get current beers on page load
  useEffect(() => {
    if (currentBeers.length !== 10) {
      getCurrentBeers().then(newBeers => {
        setCurrentBeers(JSON.parse(JSON.stringify(newBeers)));
        setSelectedBeers(newBeers);
      })
    }
  }, []);

  const getColour = (imgUrl, style) => {
    let colour = 'gold'
    if (style.toLowerCase().includes('ipa')) {
      colour = "gold"
    } else if (style.toLowerCase().includes('stout')) {
      colour = "#092215FF"
    } else if (style.toLowerCase().includes('lager')) {
      colour = "rgba(255,243,161,0.5)"
    } else if (style.toLowerCase().includes('sour')) {
      colour = 'blue'  // TODO: Make function that takes average colour from image
    }

    return colour;
  }

  const findMissingId = (beers) => {
    const expectedIds = Array.from({length: 10}, (_, i) => i + 1);
    const actualIds = beers.map(beer => beer.position);
    return expectedIds.find(id => !actualIds.includes(id));
  }

  function getBeerDifferences(originalBeers, newBeers) {
    const stringifyBeer = (beer) => JSON.stringify(beer);

    const originalBeerSet = new Set(originalBeers.map(stringifyBeer));
    const newBeerSet = new Set(newBeers.map(stringifyBeer));
    console.log(originalBeerSet, newBeerSet)

    const removedBeers = originalBeers.filter(beer => !newBeerSet.has(stringifyBeer(beer)));
    const addedBeers = newBeers.filter(beer => !originalBeerSet.has(stringifyBeer(beer)));

    return {removedBeers, addedBeers};
  }

  return (
    <div>
      {user === null ? <div></div> :
        <div className={'beerAdminContainer'}>
          <div className={'loading'} style={{display: startUpload ? 'flex' : 'none'}}>SAVING...
            ({progressString})
          </div>
          <ColourPicker selectedBeers={selectedBeers} setSelectedBeers={setSelectedBeers} index={colourIndex}
                        colour={currentItemColour} className={'colourPicker'} open={pickerOpen}
                        setOpen={setPickerOpen}/>
          <div className={'beerSelectionContainer'}>
            <h1 className={'adminHeader'}>UPDATE BEERS</h1>
            <div>Search for a <i><b>beer</b></i>! Best practice is to search <i><b>Brewery Name + Beer Name</b></i>,
              e.g. Vault City Grape Soda
            </div>
            <div className={'beersLogoList'} style={{gridTemplateColumns: loadingBeers ? 'repeat(1, 1fr)' : 'repeat(8, 1fr)'}}>
              <div style={{display: loadingBeers ? 'flex' : 'none'}} className={'beersLoadingContainer'}>
                <img src={Loading}/>
              </div>
              {foundBeers.map((item, i) =>
                <div className={'foundBeerContainer'}>
                  <img key={i} onClick={() => {
                    if (selectedBeers.indexOf(item) <= -1) {
                      let position = findMissingId(selectedBeers)
                      if (position === undefined) {
                        position = selectedBeers.length + 1
                      }
                      let beer = new Beer(
                        item.name, item.description, item.brewery, item.brewery_location, item.brewery_country,
                        item.original_label_image_hd, item.abv, item.style,
                        `https://untappd.com/b/${item.untappd_beer_slug}/${item.untappd_id}`,
                        getColour(item.original_label_image_hd, item.style), position, Timestamp.now());
                      setSelectedBeers(selectedBeers => [...selectedBeers, beer]);
                    }
                  }} src={item.original_label_image_hd} alt={`${item.name} logo`}/>
                  <div>{item.name}</div>
                </div>)}
            </div>
            <div className={'beerSelectionFooter'}>
              <div className={'beerFooterSearchInfo'}>
                <input className={'adminInput'} style={{padding: '10px'}} onChange={(evt) => {
                  setBeerName(evt.target.value);
                }} onKeyDown={(e) => {
                  if (e.key === "Enter") getBeers()
                }}/>
                <button className={'adminBtn'} onClick={() => {
                  getBeers()
                }}>SEARCH
                </button>
              </div>
              <div className={'beerFooterSaveInfo'}>
                <div>
                  {`${selectedBeers.length} beers selected`}
                </div>
                <button className={'adminBtn'} onClick={() => {
                  saveBeers()
                }}>
                  SAVE
                </button>
              </div>
            </div>
          </div>
          <div className={'basketContainer'}>
            <div className={'itemsInBasket'}>
              {/*.sort((a, b) => parseInt(a.position) > parseInt(b.position) ? 1 : -1)*/}
              {selectedBeers.map((item, index) =>
                <div style={{backgroundColor: item.tapColour}} className={'beerItem'} key={index}>
                  <div>
                    <input className={'adminInput positionInput'} tabIndex={index + 1} placeholder={'Position'}
                           type={'number'} onChange={(event) => {
                      updatePosition(index, event.target.value)
                    }} value={`${item.position}`}></input>
                    <img className={'basketBeerImg'} onClick={() => {
                      handleDelete(selectedBeers, setSelectedBeers, index)
                    }} src={item.imageUrl instanceof File ? URL.createObjectURL(item.imageUrl) : item.imageUrl}
                         alt={`${item.name} logo`}/>
                    <div>{item.name}</div>
                  </div>
                  <div className={'beerBtnContainer'}>
                    <button className={'adminBtn beerButton'} onClick={() => {
                      setCurrentItemColour(item.tapColour)
                      setColourIndex(index)
                      setPickerOpen(true)
                    }}><img className={'adminBeerBtnImg'} src={Eyedropper}/></button>
                    <div>
                      <label htmlFor={`beerImgUpload${index}`} className={'adminBtn beerButton'}><img
                        className={'adminBeerBtnImg'} src={Upload}/></label>
                      <input
                        type="file"
                        id={`beerImgUpload${index}`}
                        style={{display: 'none'}}
                        onChange={(event) => {
                          let file = event.target.files[0];
                          console.log(file)

                          new Compressor(file, {
                            retainExif: false,
                            maxHeight: 1000,
                            maxWidth: 1000,
                            quality: 0.25,
                            convertSize: 1000000,

                            success(result) {
                              let compressedFile = null;
                              if (result instanceof File) compressedFile = result;
                              else {
                                compressedFile = new File([result], result.name, {
                                  type: result.type,
                                });
                              }

                              let tempBeers = []
                              for (let i = 0; i < selectedBeers.length; i++) {
                                if (index === i) {
                                  let tempBeer = selectedBeers[i];
                                  tempBeer.imageUrl = compressedFile
                                  tempBeers.push(tempBeer)
                                } else {
                                  tempBeers.push(selectedBeers[i])
                                }
                              }
                              setSelectedBeers(tempBeers);
                            }
                          })
                        }}
                      />
                    </div>
                    <button onClick={() => {
                      updateUrl(index)
                    }} className={'adminBtn beerButton'}><img className={'adminBeerBtnImg'} src={Reset}/></button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default UpdateBeers;