function pourRandomBeer(tapsOn, setTapsOn) {
    let intervalId;
    let randomLength = 5000
    const changeTaps = () => {
        const newTapsOn = [...tapsOn];

        const offTapIndices = newTapsOn.reduce((acc, val, index) => {
            if (!val) acc.push(index);
            return acc;
        }, []);

        if (offTapIndices.length === 0) return;

        const randomIndex = offTapIndices[Math.floor(Math.random() * offTapIndices.length)];

        // Instead of a ref, use a local variable to store the timeout ID
        const timeoutId = setTimeout(() => {
            setTapsOn(prevTapsOn => {
                const updatedTapsOn = [...prevTapsOn];
                updatedTapsOn[randomIndex] = false;
                return updatedTapsOn;
            });
        }, randomLength);

        // Update the state with the tap turned on and the timeout ID
        setTapsOn(prevTapsOn => {
            const updatedTapsOn = [...prevTapsOn];
            updatedTapsOn[randomIndex] = true;
            return updatedTapsOn;
        });

        // Return a cleanup function to clear the timeout
        return () => clearTimeout(timeoutId);
    };

    intervalId = setInterval(changeTaps, randomLength);
    return () => clearInterval(intervalId);
}

export default pourRandomBeer;