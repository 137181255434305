import './beerCard.css';
import './beerCardMobile.css';
import './beerCardTablet.css';

import {Beer} from "../../../objects";
import {useEffect, useRef} from "react";
import NewStar from "../newStar/NewStar";

const BeerCard = (props) => {
  let {open, setOpen, beerCard, setBeerCard} = props;
  let cardRef = useRef(null);

  const closeCard = () => {
    setOpen(false)
    setTimeout(() => {
      setBeerCard(new Beer('','', '', '', '', '', '', '', '', '', ''))
    }, 500)
  }

  useEffect(() => {
    if (cardRef !== null) {
      cardRef.current.scrollTo({
        top: 0,
        behaviour: 'smooth'
      });
    }
  }, [beerCard]);

  return (
    <div
      className={'beerCardContainer'}
      style={{opacity: open ? '100%' : '0%', pointerEvents: open ? 'visiblePainted' : 'none'}}
      onClick={() => {
        closeCard()
      }}>
      <div
        ref={cardRef}
        className={'beerCardInnerContainer'}
        style={{marginTop: open ? '5svh' : '110svh'}} onClick={(e) => {
        e.stopPropagation();
      }}>
        <div className={'beerCardUpperInnerContainer'}>
          <div className={'beerCardCloseButton'} onClick={() => {
            closeCard()
          }}>
            x
          </div>
          {/*<NewStar beer={beerCard} style={'starCard'}/>*/}
          {/*<img className={'beerCardImg'} src={beerCard.imageUrl}*/}
          {/*     alt={beerCard.name !== '' ? beerCard.name + " logo" : ''}/>*/}
          {/*<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>*/}
          {/*  <NewStar beer={beerCard} style={'starCard'}/>*/}
          {/*</div>*/}
          <img className={'beerCardImg'} src={beerCard.imageUrl}
               alt={beerCard.name !== '' ? beerCard.name + " logo" : ''}/>
          <h1 className={'beerCardName'}>{beerCard.name}</h1>
          <div className={'beerCardBrewery'}>{beerCard.brewery}</div>
          <div className={'beerCardBrewery beerCardBreweryLocation'}>{beerCard.breweryLocation}</div>
          <div className={'beerCardBrewery beerCardBreweryCountry'}>{beerCard.breweryCountry}</div>
          <div className={'beerCardDescription'}>{beerCard.description}</div>
          <div className={'beerCardInfo'}>
            <div className={'beerCardAbv'}>
              {beerCard.abv}%
            </div>
            <div className={'beerCardStyle'}>
              {beerCard.style}
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            window.open(`${beerCard.pageUrl}`)
          }}
          className={'untappdBtn'}
        >
          OPEN IN UNTAPPD
        </button>
      </div>
    </div>
  )
}

export default BeerCard;