const EmailUpdate = (props) => {
  let {bookingId, bookingInfo, calendarInfo} = props;

  return (
    <html>
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
      <title>Mosaic Tap Booking Updated</title>
    </head>
    <body>
    <div className='container'
         style={{
           fontSize: '1em',
           backgroundColor: 'white',
           fontFamily: 'BelyRegular, sans-serif',
           textAlign: 'center',
           width: '100%',
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'center',
           maxWidth: '500px'
         }}
    >
      <div>
        <img className='headerImg' style={{width: '100%', maxWidth: '500px'}}
             src="https://firebasestorage.googleapis.com/v0/b/mosaictap.appspot.com/o/Email%2FquizUpdated.png?alt=media&token=c1519f36-4775-4c77-800f-8f015a2b6cad"/>
        <h1 style={{fontFamily: 'BelyDisplayW00-Regular, sans-serif'}}>
          Hi {bookingInfo.name},
        </h1>
        <div style={{
          fontFamily: 'BelyRegular, sans-serif'
        }}>
          <div>
            Thank you for booking the <b>Mosaic Tap Quiz</b>!
          </div>
          <br/>
          <div>
            You have successfully updated your table
            for <b>{bookingInfo.size.map((item) => item).join(', ')}</b> on <b>{bookingInfo.date.split(' [SOLD OUT]')[0]}</b> at <b>{bookingInfo.time}</b>.
          </div>
          <br/>
          <b>Add to your calendar:</b>
          <br/>
          <div style={{marginTop: '15px', marginBottom: '25px'}}>
            <a style={{
              border: 'none',
              backgroundColor: '#1f87fc',
              cursor: 'pointer',
              borderRadius: '5px',
              padding: '10px',
              fontSize: '1em',
              fontFamily: 'KCIllHand, sans-serif',
              fontWeight: 'bold',
              textDecoration: 'none',
              color: 'white'
            }}
               href={`https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(calendarInfo.title)}&dates=${calendarInfo.startTime}/${calendarInfo.endTime}&details=${encodeURIComponent(calendarInfo.description)}&location=${encodeURIComponent(calendarInfo.location)}`}>
              Google Calendar
            </a>
          </div>
          <div style={{margin: '20px'}}>
            <a style={{
              border: 'none',
              backgroundColor: '#555555',
              cursor: 'pointer',
              borderRadius: '5px',
              padding: '10px',
              fontSize: '1em',
              fontFamily: 'KCIllHand, sans-serif',
              fontWeight: 'bold',
              textDecoration: 'none',
              color: 'white'
            }} download title={'Download .ics (Apple iCal) file'}
               href={`https://firebasestorage.googleapis.com/v0/b/mosaictap.appspot.com/o/ics%2F${bookingId}.ics?alt=media`}
               target="_blank" rel="noopener noreferrer">
              iCalendar
            </a>
          </div>
          <div>
            If this is a mistake, please click the button below to amend or cancel your booking.
          </div>
          <br/>
        </div>

        <a href={`https://mosaictap.com/booking/${bookingId}/${bookingInfo.email}`}
           style={{
             backgroundColor: '#06341c',
             cursor: 'pointer',
             borderRadius: '5px',
             padding: '10px',
             fontSize: '1em',
             fontFamily: 'KCIllHand, sans-serif',
             fontWeight: 'bold',
             textDecoration: 'none',
             color: 'white'
           }}>CHANGE OR CANCEL BOOKING</a>
        <br/>
        <br/>
        <div>
          <span>If the button above does not work, please navigate to: <a
            href={`https://mosaictap.com/booking/${bookingId}/${bookingInfo.email}`}>{`https://mosaictap.com/booking/${bookingId}/${bookingInfo.email}`}</a></span>
        </div>
        <br/>
        <div>
          We look forward to seeing you.
        </div>
        <div>
          <a href="https://maps.app.goo.gl/1pj5uRYbQUXaRoXs6"><i>Mosaic Tap, Arch 7, Forth Goods Yard, NE1 3PG</i></a>
        </div>
        <br/>
        <a href="https://mosaictap.com">
          <img className='footerImg' style={{width: 'auto', height: '50px'}}
               src="https://firebasestorage.googleapis.com/v0/b/mosaictap.appspot.com/o/Email%2Flogo.png?alt=media&token=f3b44f52-6d23-49b4-bc6b-9843710016f3"/>
        </a>
        <br/>
        <br/>
        <div>
        </div>
      </div>
    </div>
    </body>
    </html>
  )
}

export default EmailUpdate;