import './heroImages.css';
import './heroImagesMobile.css';
import './heroImagesTablet.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

import {useEffect, useRef, useState} from "react";
import {listAll, getStorage, ref, getDownloadURL} from "firebase/storage";
import useWindowDimensions from "../../func/getWindowDimensions";
import Slider from "react-slick";

const storage = getStorage();

const HeroImages = (props) => {
    const { height, width } = useWindowDimensions();

    const imagesRef = ref(storage, 'Hero');
    const [images, setImages] = useState([])

  useEffect(() => {
    const getCurrentImages = async () => {
      try {
        const res = await listAll(imagesRef);
        const urls = await Promise.all(res.items.map(itemRef => getDownloadURL(itemRef)));
        setImages(urls);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    getCurrentImages();
  }, []);

  const heroSettings = {
    dots: true,
    arrows: width >= 640,
    speed: 500,
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnFocus: true,
    swipeToSlide: true,
    pauseOnHover: true,
    centerMode: width >= 640,
    variableWidth: width >= 640,
  };

    return (
      <div className={"heroImagesContainer"}>
        <Slider {...heroSettings}>
            {images.map((url, index) => (
              <div key={index}>
                  <img className="slide" src={url} alt={`Slide ${index}`} />
              </div>
            ))}
        </Slider>
      </div>
    )
}

export default HeroImages;