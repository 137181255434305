import './colourPicker.css';
import './colourPickerMobile.css';

import {HuePicker, SketchPicker} from "react-color";
import React, {useEffect, useState} from "react";
import Beer from "../../objects/Beer";

const ColourPicker = (props) => {
  let open = props.open;
  let setOpen = props.setOpen;
  let propColour = props.colour;
  let selectedBeers = props.selectedBeers;
  let setSelectedBeers = props.setSelectedBeers;
  let index = props.index;

  let [chosenColour, setChosenColour] = useState()
  let [startUpload, setStartUpload] = useState(false)

  useEffect(() => {
    if (propColour !== '') {
      setChosenColour(propColour)
    }
  }, [propColour]);

  const handleChangeComplete = (color) => {
    setChosenColour(color)
  };

  const saveColour = () => {
    setStartUpload(false)
    if (chosenColour == null) {
      alert("Please choose a new colour")
    } else {
      let newBeerArr = []
      for (let i = 0; i < selectedBeers.length; i++) {
        let newBeer = selectedBeers[i]
        if (i === index) {
          newBeer.tapColour = chosenColour.hex
          newBeerArr.push(newBeer)
        } else {
          newBeerArr.push(selectedBeers[i])
        }
      }
      setSelectedBeers(newBeerArr)
      setOpen(false)
    }
  }

  useEffect(() => {
    if (startUpload) {
      saveColour()
    }
  }, [startUpload]);

  return (
      <div className={'sketchModal'} style={{display: open ? 'flex' : 'none'}} onClick={() => {
        setOpen(false)
      }}>
        <div onClick={(e) => {e.stopPropagation();}}>
          <SketchPicker
              color={chosenColour}
              onChangeComplete={handleChangeComplete}
          />
          <div className={'sketchPickerBtnContainer'}>
            <button className={'adminBtn'} onClick={() => {
              setOpen(false)
            }}>DISMISS
            </button>
            <button className={'adminBtn'} onClick={() => {
              setStartUpload(true)
            }}>SAVE
            </button>
          </div>
        </div>
      </div>
  );
}

export default ColourPicker;